<template>
  <div id="initSocket"></div>
</template>

<script>


export default {
  props: {
    WS_URL: {
      type: String,
      default: ""
    },
    startConnect: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    token() {
      return this.$store.state.socket.token
    },
    ws() {
      return this.$store.state.socket.ws
    }
  },
  data() {
    return {
      //维持心跳定时器
      heartbeatTimer: null,
      //重连次数
      reconnection: 0,
    }
  },
  created() {
    if (!this.ws && this.startConnect) {
      this.getToken()
    }
  },
  methods: {
    async getToken() {
      if(this.$route.name === "login"){
        return
      }
      try {
        let res = await this.$http.post("/ws/token/get")
        if (res.code === 0) {
          this.$store.commit("socket/SET_TOKEN",res.data)
          this.$nextTick(() => {
            this.connectSocket()
          })
        } else {
          this.$message.error(res.msg)
        }
      } catch (err) {
        this.$message.error("WebSocket地址获取失败")
      }
    },
    connectSocket() {
      // 打开一个 web socket
      const ws = new WebSocket(process.env.VUE_APP_SOCKET_URL + this.WS_URL + this.token); //socketUrl连接地址

      ws.onopen = () => {  //连接成功回调
        console.log("连接成功");
        this.$emit("connectSuccess")
        this.reconnection = 0
        this.heartbeatTimer = setInterval(() => {
          this.send({tag: "0301", sn: 1})
        }, 1000 * 50)
        this.$store.commit("socket/SET_WS", ws)
      };

      ws.onmessage = (msg) => { //接收到消息回调
        let res = JSON.parse(msg.data)
        console.log("socket接收到消息", res)
        if (res.code === 0) {
          this.$store.commit("socket/SET_INFO", res)
        } else {
          this.$message.error(res.msg)
        }
      };

      ws.onclose = () => { //socket连接关闭时回调
        console.log("socket连接关闭")
        this.$emit("connectClose")
        this.reset()
        if (this.reconnection > 4) {
          this.$message.error("Socket重连超时")
        } else {
          setTimeout(() => {
            this.getToken()
            this.reconnection++;
          }, 1000)
        }
      };

      ws.onerror = (err) => {
        this.reset()
        console.log(err, 'Socket连接错误--------->>>>')
        this.$message.error("Socket连接错误")
      }
    },
    send(data) {
      if (this.ws) {
        this.ws.send(JSON.stringify(data));
      } else {
        this.$message.warning("WebSocket未连接")
      }
    },
    reset() {
      clearInterval(this.heartbeatTimer)
      this.heartbeatTimer = null
      if (this.ws) {
        this.ws.close()
        this.$store.commit("socket/SET_WS", null)
      }
    },
  },
}
</script>

<style lang="scss">
#initSocket {

}
</style>
